@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}

body {
  margin: 0;
  /* font-family: "Lato", sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.mobile-sidebar-modal-helper {
  position: fixed;
  left: 0;
  z-index: 1100;
}

.desktop-sidebar-helper {
  height: calc(100vh - 61px);
  position: sticky;
  left: 0;
  top: 61px;
}

.p-datatable-thead {
  border-bottom: 2px solid #e5e7eb;
}

.p-datatable-thead tr th {
  background-color: white;
}

.p-datatable-thead tr th:hover {
  background-color: #efefef;
}

.p-datatable-thead tr th div span {
  font-weight: 700;
  font-size: 16px;
  color: #333;
}

.p-sortable-column-icon {
  width: 16px;
  height: 16px;
}

.p-datatable-header {
  background-color: white;
}

.p-paginator-bottom {
  display: flex;
  align-items: center;
  padding: 20px 10px;
}

.p-paginator-bottom .p-dropdown {
  display: flex;
  align-items: center;
  border: 1px solid #e5e7eb;
  border-radius: 10px;
  padding: 20px 10px;
  height: 40px;
}

.p-dropdown-items {
  padding: 0;
}

.modal-dialog {
  margin-top: 70px !important;
}

.modal-content {
  border-radius: 16px !important;
}

.p-inputwrapper-focus {
  outline: #0098C9 !important;
  border: 1px solid #0098C9 !important;
  box-shadow: 0 0 0 0px #0098C9 !important;
}

.primeDropdownStyle {
  border: 1px solid #dee2e6;
}

.p-dropdown-filter-container {
  height: 40px;

  .p-dropdown-filter {
    height: 40px;
    border: 1px solid #dee2e6;
    border-radius: 8px;
    padding-left: 10px;
  }
}

.p-placeholder span {
  color: #9CA3AF !important;
}

input::placeholder {
  color: #9CA3AF !important;
}

.form-control {
  border: 1px solid #dee2e6 !important;
  border-radius: 8px !important;
  padding: 10px !important;
  font-size: 15px !important;
  cursor: pointer !important;
}

.form-control:focus {
  outline: #0098C9 !important;
  border: 1px solid #0098C9 !important;
  box-shadow: 0 0 0 0px #0098C9 !important;
}

.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  width: 240px;
  height: 30px;
  padding: 10px 20px;
  font-weight: 400;
  font-size: 15px;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.react-autosuggest__input:focus {
  outline: none;
}

.react-autosuggest__container--open .react-autosuggest__input {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__container--open .react-autosuggest__suggestions-container {
  display: block;
  position: absolute;
  top: 46px;
  width: 100%;
  border: 1px solid rgb(239, 239, 239);
  background-color: #fff;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  font-weight: 400;
  font-size: 15px;
  z-index: 1110;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion:not(:first-child) {
  border-top: 1px solid rgba(151, 151, 151, .1);
}

.react-autosuggest__suggestion--focused {
  color: #fff;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #f0f8ff;
}

.suggestion-content {
  display: flex;
  align-items: center;
  background-repeat: no-repeat;
}

.country {
  background-image: url("https://cdn-data.beta.hotelgyms.com/images/app/search-country-icon.svg");
  background-position: left;
}

.city {
  background-image: url("https://cdn-data.beta.hotelgyms.com/images/app/search-city-icon.svg");
  background-position: left;
}

.hotel {
  background-image: url("https://cdn-data.beta.hotelgyms.com/images/app/search-hotel-icon.svg");
  background-position: left;
}

.airport {
  background-image: url("https://cdn-data.beta.hotelgyms.com/images/app/search-airport2-icon.svg");
  background-position: left;
}

.landmark {
  background-image: url("https://cdn-data.beta.hotelgyms.com/images/app/search-station2-icon.svg");
  background-position: left;
}

.externalGym {
  background-image: url("https://cdn-data.beta.hotelgyms.com/images/app/search-region-icon.svg");
  background-position: left;
}

.region {
  background-image: url("https://cdn-data.beta.hotelgyms.com/images/app/search-region-icon.svg");
  background-position: left;
}

.name {
  margin-left: 30px;
  line-height: 20px;
}

.highlight {
  color: #0098C9;
  font-weight: 700;
}

.react-autosuggest__suggestion--focused .highlight {
  color: #120000;
}

.react-autosuggest__suggestion--focused .highlight {
  color: #120000;
}

.accordion{
  width: 100%;
  font-size: 15px;
  border-radius: 20px !important;
}
.accordion-button{
  font-size: 18px !important;
  font-weight: 700;
  box-shadow: none !important;
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.accordion-button:not(.collapsed){
  background-color: #000 !important;
  color: #fff !important;
}

.accordion-item:first-of-type {
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
  .accordion-button {
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;
  }
}

.accordion-item:last-of-type {
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}

.accordion-button:not(.collapsed)::after {
  -webkit-filter: grayscale(1) invert(1);
  filter: grayscale(1) invert(1);
}

.accordion-item:last-of-type>.accordion-header .accordion-button.collapsed {
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}

.image-gallery-thumbnail.active, .image-gallery-thumbnail:focus, .image-gallery-thumbnail:hover {
  border: 4px solid #0098C9 !important;
  border-radius: 8px !important;
}

.image-gallery-thumbnail-image{
  border-radius: 4px !important;
}

.image-gallery-icon:hover{
  color: #0098C9 !important;
}

.p-inputtextarea{
  border: 1px solid #dee2e6 !important;
  border-radius: 8px !important;
  padding: 10px !important;
}

.p-inputtextarea:focus {
  outline: #0098C9 !important;
  border: 1px solid #0098C9 !important;
  box-shadow: 0 0 0 0px #0098C9 !important;
}

.provider-dropdown-custom{
  width: 144px !important;
  max-width: 144px !important;
  min-width: 144px !important;
}

.p-dropdown-panel, .p-component, .p-ripple-disabled, .p-connected-overlay-enter-done{
  z-index: 1050 !important;
}

/* Time Picker Styles */
.ant-btn-primary{
  border-color: #0098C9 !important;
  background: #0098C9!important;
  border-radius: 4px !important;
  span{
    color: #fff !important;
  }
}
.ant-btn-primary[disabled]{
  border-color: #d9d9d9 !important;
  background: #f5f5f5 !important;
  color: rgba(0, 0, 0, 0.25) !important;
}
.ant-picker-dropdown{
  z-index: 9999 !important;
}

.ant-picker-now-btn{
  color: #333;
  text-decoration: none;
}

.label-radio-field{
  padding: 12px 0;
  .radio-text{
    display: flex;
    align-items: center;

    span{
      line-height: 19px;
      color: #333;
    }
  }
  label{
      font-size: 13px;
      padding-top: 2px;
  }
  .label-style{
      font-size: 13px !important;
  }
  .radio-input{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    select:focus {
      outline: none !important;
      border: 2px solid #0098C9 !important;
    }
  }
}

.calculator-footer-sticky{
  position: sticky;
  bottom: 0;
  z-index: 10;
}

.sb-avatar__text{
  span{
    color: white;
  }
}

.MuiInputBase-input{
  padding: 10px !important;
  font-size: 15px !important;
}
.MuiInputBase-root{
  border-radius: 10px !important;
}

.selected-hotel{
  display: flex;
  height: 111px;
  width: 100%;
  padding: 10px;
  background: #EAFBFF;
  border-radius: 10px;
  margin-bottom: 45px;
  .hotel-image{
    border-radius: 6px;
    object-fit: cover;
  }
  .hotel-text{
    display: flex;
    flex-direction: column;
    margin-left: 15px;
    z-index: 0;
    .hotel-name{
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      color: #333;
    }
    .hotel-address{
      font-size: 14px;
      line-height: 17px;
      color: #555555;
  }
  }
}

.not-selected-hotel{
  height: 111px;
  width: 100%;
  margin-bottom: 45px;
  span{
    font-size: 12px;
    color: #a9a9a9;
  }
}
.no-margin{
  margin-bottom: 0;
}

.truncate-text {
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Limit to 3 lines */
  line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}