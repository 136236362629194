:root {
  color-scheme: light;
}

html {
  font-size: 13px;
  height: -webkit-stretch;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  width: 100%;
  overflow-y: auto;
  overflow-y: overlay;
  font-family: "Lato", sans-serif !important;
  font-display: swap;
}

::selection {
  background-color: #0098C9;
  color: white;
}

p {
  font-size: 15px;
  font-weight: 400;
  color: #333;
}

span {
  font-size: 15px;
  font-weight: 400;
  color: #333;
}

a {
  transition: all 0.3s ease;
}

a:hover {
  text-decoration: none;
}

h1 {
  font-size: 30px;
  font-weight: 700;
}

h2 {
  font-size: 24px;
  font-weight: 700;
}

h3 {
  font-size: 20px;
  font-weight: 700;
}

h4 {
  font-size: 17px;
  font-weight: 700;
}

h5 {
  font-size: 15px;
  font-weight: 700;
}

h6 {
  font-size: 13px;
  font-weight: 700;
}

ul {
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
}

button:focus {
  outline: none;
}

input:focus {
  outline: #0098C9 !important;
  border: 1px solid #0098C9 !important;
}

.rotate-anchor-180 {
  transform: rotate(180deg);
}

.rotate-anchor-90 {
  transform: rotate(90deg);
}

.rotate-anchor-90reverse {
  transform: rotate(-90deg);
}
